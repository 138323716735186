<template>
  <div v-if="connectionInfo && show" id="connection-info">
    <div id="connection-icon" :class="connectionInfo.type">
      {{ environmentLabel }}
    </div>

    <b-tooltip target="status-icon"
               placement="right"
               offset="60"
    >
      <div>
        <strong>db: </strong>{{ connectionInfo.db === '' ? '*protected*' : connectionInfo.db }}
      </div>
    </b-tooltip>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    name: 'ConnectionInfo',
    computed: {
      ...mapGetters('app', [
        'connectionInfo',
        'connectedToProduction',
      ]),
      ...mapGetters('session', [
        'isAdminImpersonatingClient',
      ]),
      show() {
        return !this.connectedToProduction
      },
      environmentLabel() {
        if (!this.connectionInfo) return ''
        let label = this.connectionInfo.match
        if (this.isAdminImpersonatingClient) {
          label += '*'
        }
        return label
      },
    },
    async created() {
      await this.loadConnectionInfo()
    },
    methods: {
      ...mapActions('app', [
        'loadConnectionInfo',
      ]),
    },
  }
</script>

<style lang="scss">
  .tooltip-inner {
    max-width: none !important;
    * {
      text-align: left !important;
    }
  }
  #connection-info {
    #connection-icon {
      position: fixed;
      left: 10px;
      top: 10px;
      z-index: 10000;
      height: 20px;
      border-radius: 4px;
      font-size: smaller;
      padding: 0 6px;
      color: white;
      font-weight: 800;
      &.production {
        background: $danger;
      }
      &.staging {
        background: $warning;
      }
      &.test {
        background: $primary;
      }
      &.local {
        background: $success;
      }
      &.other {
        background: $info;
      }
    }
  }
</style>
